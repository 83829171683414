import React from 'react'
import { Text, Heading } from 'theme-ui'
import Divider from '@components/Divider'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    background: t => `
      linear-gradient(
        45deg,
        ${t.colors.omegaDark} 32.5%,
        ${t.colors.omega} 50.5%)
    `,
    color: `omega`,
    WebkitBackgroundClip: `text`,
    WebkitTextFillColor: `transparent`,
    fontWeight: `bold`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `normal`,
    maxWidth: [`full`, null, `2/3`]
  }
}

export default () => (
  <>
    <Divider space={3} />
    <Heading variant='h1' sx={styles.heading}>
      Welcome to broken-ear.io
    </Heading>
    <Divider space={3} />
    <Heading variant='h3' sx={styles.running}>
        Home of Babu Srinivasan's blog posts on topics such as AI/ML(Automatic Speech Recognition and 
        Natural Language Processing), Distributed computing, Parallel programming, Woodworking and Magic. 
        <Divider space={3} />
      <Text sx={{fontSize:2}} variant='p'>Disclaimer: Babu Srinivasan, author of broken-ear.io and its content, works for Google. Opinions and statements on this website are his own.</Text>
    </Heading>
  </>
)
